import axiosInst from "./config/axiosConfig";

export interface OrgValueTypes {
  username: string;
  groupname: string;
  action: string;
}

export interface UserListTypes {
  username: string;
  rolename: string;
  token: string | undefined;
}

interface PowerUserListTypes {
  username: string;
  token: string | undefined;
}

interface PowerUserValueTypes {
  username: string;
  name: string;
  phonenumber: string;
  email: string;
}

export interface SuppoerUserInfoTypes {
  username: string;
  supportname: string;
  allow?: string;
  email?: string;
  newgroupname?: string;
  oldgroupname?: string;
  action?: string;
  groupname?: string;
}

export const getUserList = async (body: UserListTypes) => axiosInst.post("/beta/usersList", body);

export const addRemoveOrg = async (body: OrgValueTypes) => axiosInst.post("/beta/groups", body);

export const addPowerUser = async (body: PowerUserValueTypes) => axiosInst.post("/beta/poweruser", body);

export const getPowerUserList = async (body: PowerUserListTypes) => axiosInst.post("/beta/powerusersList", body);

export const getSupportUserInfo = async (body: SuppoerUserInfoTypes) => axiosInst.post("/beta/supportUser", body);

export const getOrganizationList = async (body: { username: string; token: string }) =>
  axiosInst.post("/beta/poweruserGroups", body);
