import Geohash from "latlon-geohash";
import { getSignalStrengthColor } from "utils/util-functions";
import {
  checkedDeviceListArray,
  checkedPoolListArray,
  mapDataPoints,
  poolDeviceList,
  isSelectAllChecked,
  fetchedMappingData,
  deviceListCounter,
  fetchedMappingDataTypes,
  MapDataTypes,
  fetchedMapPointTypes,
  FormattedMapPointTypes,
} from "depot/map-page.depot";
import { PoolDeviceTypes } from "pages/Pools/pools.config";
import axiosInst from "./config/axiosConfig";
import { getDeviceInPool } from "./DevicePoolHelpers";

export const mockMappingData = {
  mappingData: [
    {
      device: {
        sn: "232401002537",
        imei: "015681000026625",
        ph: "8589236483",
        al: "ATEL10",
      },
      dataPoints: [
        {
          timeStamp: 1707534147000,
          geohash: "9musmwg42c4q",
          rsrp: -101,
          rssi: -67,
        },
        {
          timeStamp: 1707534147000,
          geohash: "9musmwg42c4q",
          rsrp: -101,
          rssi: -67,
        },
        {
          timeStamp: 1707534081000,
          geohash: "9musmwdryg4u",
          rsrp: -95,
          rssi: -62,
        },
        {
          timeStamp: 1707534015000,
          geohash: "9musmx7yxygu",
          rsrp: -96,
          rssi: -60,
        },
        {
          timeStamp: 1707533957000,
          geohash: "9musmzd84cqg",
          rsrp: -62,
          rssi: -31,
        },
        {
          timeStamp: 1707533895000,
          geohash: "9mustbsmknwt",
          rsrp: -73,
          rssi: -43,
        },
        {
          timeStamp: 1707533831000,
          geohash: "9mustbsmknkj",
          rsrp: -72,
          rssi: -39,
        },
        {
          timeStamp: 1707533769000,
          geohash: "9musw0ys93ev",
          rsrp: -90,
          rssi: -57,
        },
        {
          timeStamp: 1707533709000,
          geohash: "9musw84gq7bb",
          rsrp: -92,
          rssi: -56,
        },
        {
          timeStamp: 1707533645000,
          geohash: "9musrnc6bn5y",
          rsrp: -63,
          rssi: -37,
        },
        {
          timeStamp: 1707533585000,
          geohash: "9musrm1uj480",
          rsrp: -77,
          rssi: -50,
        },
        {
          timeStamp: 1707533519000,
          geohash: "9musrd22pun0",
          rsrp: -84,
          rssi: -59,
        },
        {
          timeStamp: 1707533459000,
          geohash: "9musrb06380c",
          rsrp: -56,
          rssi: -32,
        },
        {
          timeStamp: 1707533399000,
          geohash: "9muspvz3qr8n",
          rsrp: -80,
          rssi: -53,
        },
        {
          timeStamp: 1707533335000,
          geohash: "9muu04cpjjr4",
          rsrp: -81,
          rssi: -59,
        },
        {
          timeStamp: 1707533273000,
          geohash: "9muspbn3hs1y",
          rsrp: -75,
          rssi: -43,
        },
        {
          timeStamp: 1707533213000,
          geohash: "9muezv4k7q8d",
          rsrp: -99,
          rssi: -64,
        },
        {
          timeStamp: 1707533147000,
          geohash: "9muezg8yt2gk",
          rsrp: -92,
          rssi: -63,
        },
        {
          timeStamp: 1707533087000,
          geohash: "9muezdxmzhs9",
          rsrp: -94,
          rssi: -64,
        },
        {
          timeStamp: 1707533023000,
          geohash: "9muezd5rmrgx",
          rsrp: -88,
          rssi: -55,
        },
        {
          timeStamp: 1707532963000,
          geohash: "9muezdg6b05q",
          rsrp: -90,
          rssi: -61,
        },
        {
          timeStamp: 1707532901000,
          geohash: "9muezdgkb75r",
          rsrp: -81,
          rssi: -57,
        },
        {
          timeStamp: 1707532837000,
          geohash: "9muezdgkb7d3",
          rsrp: -81,
          rssi: -59,
        },
        {
          timeStamp: 1707532777000,
          geohash: "9muezdgkbk1s",
          rsrp: -82,
          rssi: -57,
        },
        {
          timeStamp: 1707532713000,
          geohash: "9muezdgkbk3u",
          rsrp: -81,
          rssi: -56,
        },
        {
          timeStamp: 1707532653000,
          geohash: "9muezdgkb6ue",
          rsrp: -80,
          rssi: -56,
        },
      ],
    },
  ],
};

export const getMappingData = (serialNumbers: string[], dataFields: string[]) => {
  let paramStr = "";
  if (serialNumbers && serialNumbers.length) {
    paramStr = serialNumbers.map((number: string) => `sn=${number}`).join("&");
  }
  if (dataFields && dataFields.length) {
    paramStr += "&";
    paramStr += dataFields.map((field: string) => `df=${field}`).join("&");
  }

  return axiosInst.get(`/beta/mappingUIHook?${paramStr}`);
};

const formatFetchedMapPoints = (data: { mappingData: fetchedMappingDataTypes[] }): MapDataTypes[] => {
  const deviceData: MapDataTypes[] = data?.mappingData?.map(
    ({ dataPoints, device }: fetchedMappingDataTypes): MapDataTypes => {
      const { sn: oneSerialNumber } = device;
      const locationPoints = [...dataPoints]?.sort(
        (a: fetchedMapPointTypes, b: fetchedMapPointTypes) => a.timeStamp - b.timeStamp,
      );

      const locations: FormattedMapPointTypes[] = locationPoints
        .map(({ geohash, rssi, rsrp, timeStamp }: fetchedMapPointTypes) => ({
          timeStamp,
          time: new Date(timeStamp).toString(),
          color: getSignalStrengthColor(rsrp),
          rssi,
          rsrp,
          ...(geohash ? Geohash.decode(geohash) : {}),
        }))
        .filter((x) => x.lat && x.lon);

      return {
        locations,
        sn: oneSerialNumber,
      };
    },
  );
  return deviceData;
};

export async function getDeviceMapPoints(serialNumber: string, dataFieldStr: string, isChecked: boolean | undefined) {
  try {
    const response = await axiosInst.get(`/beta/mappingUIHook?sn=${serialNumber}${dataFieldStr}`);
    const fetchedData = response?.data;
    const combinedMappingData = fetchedMappingData.data;

    const { mappingData } = fetchedData;

    combinedMappingData.push(mappingData[0]);

    // Sends data to fetchedMappingData to be used on mape page for data csv download
    const filteredCombinedData = combinedMappingData.filter((obj1, i, arr) => {
      return arr.findIndex((obj2) => obj2.device.sn === obj1.device.sn) === i;
    });

    if (!isChecked) {
      const onlyCheckedData = filteredCombinedData.filter((obj) => {
        return obj.device.sn !== serialNumber;
      });
      fetchedMappingData.setData(onlyCheckedData);
    } else {
      fetchedMappingData.setData(filteredCombinedData);
    }

    // Sends formatted fetchedData to mapDataPoints in depot to be displayed on map
    if (!fetchedData || !isChecked) {
      return mapDataPoints.setData({
        sn: serialNumber,
        mapData: null,
      });
    }

    const formattedData = formatFetchedMapPoints(fetchedData)[0];
    return mapDataPoints.setData({
      sn: serialNumber,
      mapData: formattedData,
    });
  } catch (dataPointsERROR) {
    console.debug("ERROR", dataPointsERROR);
  }
}

export const filterCheckedDevices = (checked: boolean, serialNumber: string) => {
  const checkedList = checkedDeviceListArray.data;
  const newList = checkedList.filter((item) => item !== serialNumber);

  if (checked) {
    newList.push(serialNumber);
  }

  checkedDeviceListArray.setData(newList);
};

export const filterCheckedPools = (checked: boolean, poolId: string) => {
  const checkedList = checkedPoolListArray.data;

  const newList = checkedList.filter((item) => item !== poolId);

  if (checked) {
    newList.push(poolId);
  }

  checkedPoolListArray.setData(newList);
};
export function getFomattedDeviceData(keyField: string, poolId: string) {
  const poolValues = poolDeviceList.data[poolId]?.devices;
  const checkedPoolList = checkedPoolListArray.data;
  const checkedDeviceList = checkedDeviceListArray.data;
  const isSelectAllObj = isSelectAllChecked.data;

  if (!poolValues || !poolValues.length) return [];

  poolValues.sort((device1: PoolDeviceTypes, device2: PoolDeviceTypes) => {
    const value1 = device1[keyField as keyof PoolDeviceTypes] || "";
    const value2 = device2[keyField as keyof PoolDeviceTypes] || "";
    if (value1 > value2) {
      return 1;
    }
    if (value2 > value1) {
      return -1;
    }
    return 0;
  });

  if (isSelectAllObj[poolId]) {
    return poolValues.map(({ serialNumber, ...rest }) => ({
      serialNumber,
      checked: !!checkedPoolList.includes(poolId),
      ...rest,
    }));
  }

  return poolValues.map(({ serialNumber, ...rest }) => ({
    serialNumber,
    checked: !!checkedDeviceList.includes(serialNumber),
    ...rest,
  }));
}

export function setCheckAll(poolId: string, status = true) {
  const isSelectAllDepot = isSelectAllChecked.data;
  isSelectAllDepot[poolId] = status;
  isSelectAllChecked.setData(isSelectAllDepot);
}

export const getPoolDeviceList = async (poolId: string) => {
  const storedCounts = deviceListCounter.data;
  const deviceListCounts = { ...storedCounts };

  if (!(poolId in storedCounts)) {
    deviceListCounts[poolId] = 0;
    deviceListCounter.setData(deviceListCounts);
  } else {
    const value = storedCounts[poolId];
    deviceListCounts[poolId] = value + 1;

    deviceListCounter.setData(deviceListCounts);
  }

  await getDeviceInPool(poolId);
};
