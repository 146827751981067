import styled from "@emotion/styled";
import Select from "@mui/material/Select";
import TreeItem from "@mui/lab/TreeItem";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

import BREAKPOINTS from "utils/breakpoints";
import { NavLink } from "react-router-dom";
import { TreeView } from "@mui/lab";

export const KeyFieldSelect = styled(Select)`
  .MuiInputBase-input {
    font-size: 14px;
  }
`;

export const SidebarWrapper = styled.div`
  width: 280px;
  min-width: 220px;
  height: 100%;
  margin-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 20px;
  position: relative;

  ${BREAKPOINTS.lg} {
    width: 240px;
  }

  ${BREAKPOINTS.md} {
    display: none;
  }
`;

export const DrawerWrapper = styled.div`
  width: 220px;
  min-width: 220px;
  height: calc(100% - 40px);
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 15px;

  .active-device {
    label {
      background-color: ${({ theme }) => theme.palette.gray[200]};
    }
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadMoreButton = styled(Button)`
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 10px;
  font-weight: normal;
  line-height: 1.5;
`;

export const ContentWrapper = styled.div`
  .header-title {
    text-align: center;
  }
  overflow: auto;

  color: ${({ theme }) => theme.palette.common.black};
`;

export const DeviceTreeView = styled(TreeView)`
  boxshadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 270px);
`;

export const DeviceTreeItem = styled(TreeItem)`
  padding-left: 4px;
  .MuiTreeItem-iconContainer {
    width: 0px;
  }

  .MuiCheckbox-root {
    padding: 0px;
  }

  .Mui-selected {
    background-color: rgba(79, 174, 224, 0.3);
  }

  svg {
    width: 17px;
    margin-right: 3px;
  }
`;

export const IconLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TreeLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconTreeItem = styled(TreeItem)`
  &.pool-node,
  &.org-node {
    & > div.MuiTreeItem-content {
      padding-left: 0px;
      padding-right: 0px;
      .MuiTreeItem-label {
        padding-left: 0px;
      }
    }
  }

  svg {
    width: 17px;
    margin-right: 3px;
  }

  .MuiCheckbox-root {
    padding: 0px;
  }
`;

export const Spinner = styled(CircularProgress)`
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px;
`;

export const DevicePageLink = styled(NavLink)`
  display: flex;
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
