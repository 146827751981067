import { DeviceColumnTypes } from "pages/Devices/devices.config";
import { COLUMN_TYPE } from "utils/constants";
import { formatPhoneNumber } from "utils/util-functions";

export const ORG_COLUMNS: DeviceColumnTypes[] = [
  {
    id: "GroupName",
    label: "Organization Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  { id: "action-col", label: "Request Access" },
];

export const SUPPORT_USER_COLUMNS: DeviceColumnTypes[] = [
  {
    id: "name",
    label: "Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "email",
    label: "Email",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "phone_number",
    label: "Phone Number",
    format: (value: any) => formatPhoneNumber(value || ""),
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "profile",
    label: "Organization",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "custom:Org_role",
    label: "Role",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "status",
    label: "Request Status",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  { id: "action-col", label: "" },
];
