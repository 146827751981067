import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { CommonText } from "../text/text.component";
import style from "./legal-footer.module.css";

export function LegalFooter({ onClick }) {
  const currentYear = new Date().getFullYear();

  return (
    <div className={style.container}>
      <div className={style.textContainer}>
        <CommonText>
          {`© ${currentYear} `}
          <Link to="https://nextivityinc.com/" target="_blank" rel="noopener noreferrer">
            Nextivity Inc.
          </Link>{" "}
          All rights reserved. <Link onClick={onClick}>Terms of Use.</Link> {process.env.REACT_APP_VERSION}
        </CommonText>
      </div>
    </div>
  );
}

LegalFooter.propTypes = {
  onClick: PropTypes.func,
};

LegalFooter.defaultProps = {
  onClick: null,
};
