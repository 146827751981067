import { AttributesTypes } from "pages/configs/configs";
import axiosInst from "./config/axiosConfig";

export interface UserListByGroupResponseTypes {
  Username: string;
  Attributes: AttributesTypes[];
  UserCreateDate: string;
  UserLastModifiedDate: string;
  Enabled: boolean;
  UserStatus: string;
}

export interface UserListByGroupTypes {
  username: string;
  groupname: string;
  token: string | undefined;
}

interface UsersAddTypes {
  username: string;
  groupname: string;
  name: string;
  phonenumber: string;
  email: string;
  role: string;
}

export interface UsersDeleteTypes {
  username: string;
  userEmail: string | undefined;
}

const getUserListByGroup = async (body: UserListByGroupTypes) => axiosInst.post("/beta/usersListByGroup", body);

const createNewUser = async (body: UsersAddTypes) => axiosInst.post("/beta/usersAdd", body);

const deleteUser = async (body: UsersDeleteTypes) => axiosInst.post("/beta/usersDelete", body);

export { getUserListByGroup, createNewUser, deleteUser };
