import React, { BaseSyntheticEvent, useMemo, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableRow from "@mui/material/TableRow";
import { FieldValues, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import { useTheme } from "@mui/material";
import { onEnter } from "utils/general";
import { PageTitle } from "components/common/Text";
import { createNewPool, deleteDevicePool } from "api/DevicePoolHelpers";
import { FieldError, StyledTextField } from "components/common/Input";
import { showSuccess, showError } from "utils/notifications";
import { TableBodyCell } from "components/table/table.style";
import { useRequest } from "hooks/useRequest";
import useConfirm from "hooks/useConfirm";
import CustomTable from "components/table/table.component";
import { getCurrentPage, getFilteredListNew, getSortList, nameValidationCheck } from "utils/util-functions";
import { Popup } from "components/common/popup/popup.component";
import { Spinner } from "components/common/spinner/spinner.component";
import { useAuth } from "hooks/useProvideAuth";
import TableFilterNew from "components/table/TableFilterNew.component";
import { ButtonText } from "components/common/Buttons";
import POOLS_COLUMNS, { DevicePoolTypes, PoolColumnTypes } from "./pools.config";
import style from "./pools.page.module.css";

const PoolsPage = (): JSX.Element => {
  const [isOpenNewPoolModal, setIsOpenNewPoolModal] = useState<boolean>(false);
  const [isCreatingNewPool, setIsCreatingNewPool] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [filterColumn, setFilterColumn] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");
  const [filterWord, setFilterWord] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");

  const theme = useTheme();
  const [getConfirmation, Confirmation] = useConfirm();
  const { organizationName: organizationId }: Record<string, string> = useAuth();

  const filterColumns = useMemo(() => POOLS_COLUMNS.filter(({ filter }) => filter), [POOLS_COLUMNS]);

  const { data: poolList, isLoading, mutate } = useRequest(`devicePools`);

  const filteredList = useMemo(
    () => getFilteredListNew(poolList, filterColumn, filterType, filterWord),
    [poolList, filterColumn, filterWord, filterType],
  );

  const sortedList = useMemo(() => {
    const sortColumn = POOLS_COLUMNS.find(({ id }) => id === sortBy);
    return getSortList(filteredList, sortBy, sortOrder, sortColumn?.type);
  }, [sortBy, sortOrder, filteredList]);

  const paginatedList = useMemo(() => getCurrentPage(sortedList, page, rowsPerPage), [sortedList, page, rowsPerPage]);

  const totalPoolCount = useMemo(() => filteredList?.length || 0, [filteredList]);

  const bodyColumns = useMemo(() => {
    const tempCols = [...POOLS_COLUMNS];
    tempCols.pop();
    return tempCols;
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  }: FieldValues = useForm();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState<DevicePoolTypes | null>(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const onNewPool = () => {
    setIsOpenNewPoolModal(true);
    reset();
    clearErrors();
  };

  const closeNewPoolModal = () => {
    setIsOpenNewPoolModal(false);
  };

  const onDeletePool = async () => {
    setAnchorEl(null);

    const isConfirm = await getConfirmation({
      title: "Delete Pool?",
      content: "Do you want to delete this Device Pool?",
      buttonTxt: ButtonText.Delete,
    });

    if (isConfirm) {
      try {
        await deleteDevicePool(currentRow?.devicePool);

        mutate();
        showSuccess("Successfully deleted the device pool!");
      } catch (error) {
        // error handling
      }
    }
  };

  const handleRowClick = (poolId: string) => {
    if (poolId) {
      navigate(`/pools/${poolId}`);
    }
  };

  const onModalClose = (event: never, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick") {
      setIsOpenNewPoolModal(false);
    }
  };

  const onAddNewPool = async (poolData: FieldValues) => {
    const { devicePool } = poolData;
    if (devicePool && organizationId) {
      setIsCreatingNewPool(true);

      try {
        const res = await createNewPool(devicePool, organizationId);

        if (res.data) {
          showSuccess("Add new device pool successfully!");
          setIsOpenNewPoolModal(false);
          mutate();
        }
      } catch (error) {
        // error handling
      } finally {
        setIsCreatingNewPool(false);
      }
    } else {
      // show warning
      showError("Pool name or Organization ID is not valid!");
    }
  };

  const onClickMenu = (event: BaseSyntheticEvent, row: DevicePoolTypes) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const onSearch = (column: string, columnType: string, searchContent: string) => {
    setFilterColumn(column);
    setFilterWord(searchContent);
    setFilterType(columnType);
    setPage(0);
  };

  const onResetFilter = () => {
    setFilterColumn("");
    setFilterWord("");
    setFilterType("");
    setPage(0);
  };

  const handleRequestSort = (order: string, orderBy: string) => {
    setSortOrder(order);
    setSortBy(orderBy);
  };

  return (
    <>
      <PageTitle>Pools</PageTitle>

      <div className={style.barContainer}>
        <div className={style.filterContainer}>
          <TableFilterNew columns={filterColumns} onSearch={onSearch} onReset={onResetFilter} />
        </div>

        <div className={style.buttonOptions}>
          <NxtIconButton icon={<AddCircleOutlineIcon />} onClick={onNewPool} text="New Pool" />
        </div>
      </div>

      <CustomTable
        boxShadow
        rowsPerPageOptions={[10, 25, 100]}
        totalCount={totalPoolCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(pageNum) => setPage(pageNum)}
        onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
      >
        <CustomTable.Header columns={POOLS_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={isLoading} deviceData={paginatedList}>
          {paginatedList &&
            paginatedList.map((row: DevicePoolTypes) => (
              <TableRow hover key={row.devicePool}>
                {bodyColumns.map((column: PoolColumnTypes) => {
                  const value = row[column.id as keyof DevicePoolTypes];
                  return (
                    <TableBodyCell key={column.id} onClick={() => handleRowClick(row.devicePool)}>
                      {value}
                    </TableBodyCell>
                  );
                })}
                <TableBodyCell style={{ width: 30 }}>
                  <Button onClick={(e) => onClickMenu(e, row)} style={{ color: theme.palette.common.black }}>
                    <MoreVertIcon />
                  </Button>
                </TableBodyCell>
              </TableRow>
            ))}
        </CustomTable.Body>
      </CustomTable>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onDeletePool}>Delete</MenuItem>
      </Menu>

      <Popup
        title="New Pool"
        open={isOpenNewPoolModal}
        onClose={onModalClose}
        primaryBtn={{ text: "Save", onClick: handleSubmit(onAddNewPool), disabled: isCreatingNewPool }}
        secondaryBtn={{ text: "Cancel", onClick: () => closeNewPoolModal() }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <StyledTextField
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onAddNewPool))}
            label="Pool Label"
            {...register("devicePool", {
              required: "Pool Name is required!",
              validate: {
                nameValidationCheck,
              },
            })}
            error={errors.devicePool}
          />

          {errors.devicePool && <FieldError>{errors.devicePool.message}</FieldError>}
        </Box>
      </Popup>

      <Popup title="Creating New Pool..." open={isCreatingNewPool}>
        <Spinner />
      </Popup>

      <Confirmation />
    </>
  );
};

export default PoolsPage;
