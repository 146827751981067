import React, { useState, useCallback } from "react";
import { Popup } from "components/common/popup/popup.component";

const createPromise = async () => {
  let resolver;

  return [
    new Promise((resolve) => {
      resolver = resolve;
    }),
    resolver,
  ];
};

const useConfirm = (): JSX.Element | any => {
  const [open, setOpen] = useState<boolean>(false);
  const [resolver, setResolver] = useState<any>({ resolver: null });
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [buttonText, setButtonText] = useState("");

  const getConfirmation = useCallback(
    async ({ title: newTitle, content: newContent, buttonTxt: newButtonLabel }: any) => {
      setTitle(newTitle);
      setContent(newContent);
      setButtonText(newButtonLabel);
      setOpen(true);

      const [promise, resolve] = await createPromise();

      setResolver({ resolve });

      return promise;
    },
    [],
  );

  const onClick = async (status: boolean) => {
    setOpen(false);
    resolver.resolve(status);
  };

  const Confirmation = () => (
    <Popup
      open={open}
      title={title}
      primaryBtn={{ onClick: () => onClick(true), text: buttonText }}
      secondaryBtn={{ onClick: () => onClick(false), text: "Cancel" }}
    >
      <div style={{ paddingBottom: "16px" }}>{content}</div>
    </Popup>
  );

  return [getConfirmation, Confirmation];
};

export default useConfirm;
