import { PoolDeviceTypes } from "pages/Pools/pools.config";

export const POOL_LIST_PAGE_SIZE = 100;

export const DEVICE_LIST_PAGE_SIZE = 100;

export interface KEY_FIELD_IDS_TYPES {
  SERIAL_NO: string;
  PHONE_NUMBER: string;
  DEVICE_ALIAS: string;
}

export const KEY_FIELD_IDS: KEY_FIELD_IDS_TYPES = {
  SERIAL_NO: "serialNumber", // This is an actual field name on API response
  PHONE_NUMBER: "PhoneNumber", // This is an actual field name on API response
  DEVICE_ALIAS: "deviceAlias", // This is an actual field name on API response
};

export interface MISSING_DESC_TYPES {
  PhoneNumber: string;
  deviceAlias: string;
}

export const MISSING_DESC: MISSING_DESC_TYPES = {
  PhoneNumber: "There is no phone number related to the device, please sort by Serial Number.",
  deviceAlias: "There is no alias name related to the device, please sort by Serial Number.",
};

export const AVAILABLE_KEY_FIELDS = [
  {
    id: KEY_FIELD_IDS.SERIAL_NO,
    label: "Serial Number",
  },
  {
    id: KEY_FIELD_IDS.PHONE_NUMBER,
    label: "SIM Phone No",
  },
  {
    id: KEY_FIELD_IDS.DEVICE_ALIAS,
    label: "Device Alias",
  },
];
