import { PoolDeviceListTypes } from "pages/Pools/pools.config";
import { DepotBin } from "./depot-bin";

// TODO Replace depot vars with a class service

// All map data can be accessed from here. It can be retrieved, set, oberserved, and cleared.

export interface DevicePoolDetailTypes {
  defaultConfigID: string;
  deviceCount: number;
  devicePool: string;
  orgGroup: string;
  orgID: string;
  userName: string;
}

interface DevicePoolDataTypes {
  devicePoolData: DevicePoolDetailTypes[];
  totalDevicePoolsInOrg: number;
}

export interface fetchedMapPointTypes {
  timeStamp: number;
  geohash?: string | undefined;
  rsrp: number | undefined;
  rssi: number | undefined;
}

export interface MapDataTypes {
  locations: fetchedMapPointTypes[];
  sn: string;
}

export interface MapDataPointTypes {
  sn: string;
  mapData: MapDataTypes | null;
}

export interface fetchedMappingDeviceTypes {
  al: string;
  imei: string;
  ph: string;
  sn: string;
}

export interface MarkerInstanceArrayTypes {
  time: string;
  rssi: number;
  rsrp: number;
  lat: number;
  lon: number;
  color: string;
}

export interface MarkerArrayTypes {
  markerInstanceArray: MarkerInstanceArrayTypes[];
  serialNumber: string;
}

export interface FormattedMapPointTypes extends fetchedMapPointTypes {
  lat?: number;
  lon?: number;
  color?: string;
  time?: string;
}

export interface DeviceArrayLocationTypes {
  color: string;
  lat: number;
  lon: number;
  rsrp: number;
  rssi: number;
  time: string;
  timeStamp: number;
}

export interface fetchedMappingDataTypes {
  dataPoints: fetchedMapPointTypes[];
  device: fetchedMappingDeviceTypes;
}

export interface MapDataCSVTypes {
  DateTime_GPS: number;
  al: string;
  imei: string;
  lat?: number;
  lon?: number;
  ph?: string;
  rsrp: string;
  rssi: string;
  sn: string;
  type: string;
}

// export interface MapDataInterface {
//   poolData: DevicePoolDataTypes;
//   pageCounter: number;
//   deviceListCounter: Record<string, number>;
//   poolDeviceList: PoolDeviceListTypes;
//   mapDataPoints: MapDataPointTypes;
//   fetchedMappingData: fetchedMappingDataTypes[];
//   checkedDeviceListArray: string[];
//   checkedPoolListArray: string[];
//   isSelectAllChecked: Record<string, boolean>;
// }

export const poolData = new DepotBin<DevicePoolDataTypes>({
  devicePoolData: [],
  totalDevicePoolsInOrg: 0,
});

export const pageCounter = new DepotBin<number>(0);

export const deviceListCounter = new DepotBin<Record<string, number>>({});

export const poolDeviceList = new DepotBin<PoolDeviceListTypes>({
  sn: {
    devices: [],
    isReachingEnd: true,
  },
});

export const mapDataPoints = new DepotBin<MapDataPointTypes>({
  sn: "",
  mapData: {
    locations: [],
    sn: "",
  },
});

export const fetchedMappingData = new DepotBin<fetchedMappingDataTypes[]>([]);

export const checkedDeviceListArray = new DepotBin<string[]>([]);

export const checkedPoolListArray = new DepotBin<string[]>([]);

export const isSelectAllChecked = new DepotBin<Record<string, boolean>>({});

// All depot data must be cleared when user signs out or the data will persist to the next user.
export const clearDepotData = () => {
  poolData.reset();
  pageCounter.reset();
  deviceListCounter.reset();
  poolDeviceList.reset();
  mapDataPoints.reset();
  fetchedMappingData.reset();
  checkedDeviceListArray.reset();
  checkedPoolListArray.reset();
  isSelectAllChecked.reset();
};

// export class Depot {
//   readonly mapData: DepotBin<MapDataInterface> = new DepotBin<MapDataInterface>({
//     poolData: {
//       devicePoolData: [],
//       totalDevicePoolsInOrg: 0,
//     },
//     pageCounter: 0,
//     deviceListCounter: {},
//     poolDeviceList: {
//       sn: {
//         devices: [],
//         isReachingEnd: true,
//       },
//     },
//     mapDataPoints: {
//       sn: "",
//       mapData: {
//         locations: [],
//         sn: "",
//       },
//     },
//     fetchedMappingData: [],
//     checkedDeviceListArray: [],
//     checkedPoolListArray: [],
//     isSelectAllChecked: {},
//   });
// }

// export const depotService = new Depot();
