import React, { ReactNode } from "react";
import { ButtonProps } from "@mui/material";
import { TextButton } from "../Buttons";
import style from "./nxt-icon-button.module.css";

interface NxtIconButtonProps extends ButtonProps {
  icon: ReactNode;
  text: string;
}

export function NxtIconButton({ text, icon, ...props }: NxtIconButtonProps): JSX.Element {
  return (
    <TextButton {...props}>
      <div className={style.iconWrapper}>{icon}</div>
      {text}
    </TextButton>
  );
}
