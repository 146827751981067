import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "@mui/material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import MapIcon from "@mui/icons-material/Map";
import ComputerIcon from "@mui/icons-material/Computer";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useAuth } from "hooks/useProvideAuth";
import { SidebarWrapper, SidebarLinks, SidebarLink, DrawerWrapper } from "./Sidebar.style";

const MenuItems = () => {
  const { isAdmin, isPowerUser } = useAuth();

  if (isPowerUser) {
    return (
      <>
        <SidebarLink to="/orgs">
          <Diversity2Icon /> Organizations
        </SidebarLink>
        <SidebarLink to="/admins">
          <SupervisorAccountIcon /> Administrators
        </SidebarLink>
        <SidebarLink to="/power-users">
          <PeopleAltIcon /> Power Users
        </SidebarLink>
        <SidebarLink to="/support">
          <SupportAgentIcon /> Support
        </SidebarLink>
      </>
    );
  }

  if (isAdmin) {
    return (
      <>
        <SidebarLink to="/users">
          <PeopleOutlineIcon /> Users
        </SidebarLink>
        <SidebarLink to="/devices">
          <ComputerIcon /> Devices
        </SidebarLink>
        <SidebarLink to="/pools">
          <DevicesOtherIcon /> Pools
        </SidebarLink>
        <SidebarLink to="/map">
          <MapIcon /> Map
        </SidebarLink>
      </>
    );
  }

  return (
    <>
      <SidebarLink to="/devices">
        <ComputerIcon /> Devices
      </SidebarLink>
      <SidebarLink to="/pools">
        <DevicesOtherIcon /> Pools
      </SidebarLink>
      <SidebarLink to="/map">
        <MapIcon /> Map
      </SidebarLink>
    </>
  );
};

const Content = ({ isAuthenticated }) => (
  <SidebarLinks>
    <div className="awcone__navbar-links_container">{isAuthenticated && <MenuItems />}</div>
  </SidebarLinks>
);
Content.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const Sidebar = ({ showDrawer, onCloseDrawer }) => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <SidebarWrapper>
        <Content isAuthenticated={isAuthenticated} />
      </SidebarWrapper>
      <Drawer anchor="left" open={showDrawer} onClose={() => onCloseDrawer?.()}>
        <DrawerWrapper>
          <Content isAuthenticated={isAuthenticated} />
        </DrawerWrapper>
      </Drawer>
    </>
  );
};
Sidebar.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  onCloseDrawer: PropTypes.func.isRequired,
};

export default Sidebar;
