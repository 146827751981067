import React from "react";
import { Route } from "react-router-dom";
import SupportPage from "pages/PowerUser/Support/Support.page";
import PowerUsersPage from "pages/PowerUser/PowerUsers/power-users.page";
import OrganizationsPage from "pages/PowerUser/Organizations/organizations.page";
import AdminsPage from "pages/PowerUser/Admins/administration.page";
import PrivateRoute from "./components/PrivateRoute";

const PowerUserRoute = () => (
  <>
    <Route
      path="/orgs"
      element={
        <PrivateRoute>
          <OrganizationsPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/admins"
      element={
        <PrivateRoute>
          <AdminsPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/power-users"
      element={
        <PrivateRoute>
          <PowerUsersPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/support"
      element={
        <PrivateRoute>
          <SupportPage />
        </PrivateRoute>
      }
    />
  </>
);

export default PowerUserRoute;
