import { TableColumnTypes } from "pages/configs/configs";

const POOL_DEVICE_COLS: TableColumnTypes[] = [
  { id: "deviceAlias", label: "Alias" },
  { id: "devicePool", label: "Pool Name" },
  { id: "serialNumber", label: "Serial No" },
  { id: "action-col", label: "" },
];

export default POOL_DEVICE_COLS;
