import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Navigate, useNavigate } from "react-router";
import styled from "@emotion/styled";

import { isSessionValid } from "pages/Auth/Cogcognito";
import { useAuth } from "hooks/useProvideAuth";
import { showError } from "utils/notifications";
import { publicRoutes } from "./routes";

const PublicRouteWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.gray[100]};
`;

const PublicRoute = ({ children }) => {
  const isAuthenticated = isSessionValid();
  const { refetch } = useAuth();
  const [userType, setUserType] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      refetch?.()
        .then(({ isAdmin, isPowerUser }) => {
          setUserType({ isAdmin, isPowerUser });
        })
        .catch((error) => {
          showError(error.toString());
        });
    }
  }, []);

  const getRoute = () => {
    if (!userType.isAdmin && !userType.isPowerUser) {
      navigate(publicRoutes.devices);
    }

    if (userType.isPowerUser) {
      navigate(publicRoutes.orgs);
    }

    if (userType.isAdmin) {
      navigate(publicRoutes.users);
    }
  };

  return isAuthenticated ? !!userType && getRoute() : <PublicRouteWrapper>{children}</PublicRouteWrapper>;
};
PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
