import React from "react";
import { CircularProgress } from "@mui/material";
import style from "./loading-spinner.module.css";

export interface LoadingSpinnerProps {
  position?: string;
}

export default function LoadingSpinner({ position = "absolute" }: LoadingSpinnerProps): JSX.Element {
  return (
    <>
      {position === "absolute" ? (
        <div className={style.container}>
          <CircularProgress />
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
