import { COLUMN_TYPE } from "utils/constants";

export interface DevicePoolTypes {
  defaultConfigID: string;
  deviceCount: number;
  devicePool: string;
  orgGroup: string;
  orgID: string;
  userName: string;
}

export interface PoolDeviceTypes {
  PhoneNumber: string;
  deviceAlias: string;
  checked?: boolean;
  deviceDescription: string;
  devicePool: string;
  imei: string;
  locationTrailEnabled: boolean;
  orgID: string;
  portNumber: string;
  serialNumber: string;
  userName: string;
  vpnAllowedIP: string;
  vpnPARequestTime: string;
}

export interface PoolDeviceListTypes {
  [poolId: string]: {
    devices: PoolDeviceTypes[];
    isReachingEnd: boolean;
  };
}

export interface PoolColumnTypes {
  id: string;
  label: string;
  filter?: boolean;
  isSort?: boolean;
  type?: string;
}

const POOLS_COLUMNS: PoolColumnTypes[] = [
  {
    id: "devicePool",
    label: "Pool Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "userName",
    label: "User Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "deviceCount",
    label: "Device Count",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.NUMBER,
  },
  { id: "action-col", label: "" },
];

export default POOLS_COLUMNS;
