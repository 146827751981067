import React from "react";
import { Route, Navigate } from "react-router-dom";
import Login from "pages/Auth/Login/login.page";
import VerifyEmail from "pages/Auth/VerifyEmail";
import ForgotPassword from "pages/Auth/ForgotPassword";
import PublicRoute from "./components/PublicRoute";

const AuthRoute = () => (
  <>
    <Route path="/" element={<Navigate to="/login" />} />
    <Route
      path="/verify-email"
      element={
        <PublicRoute>
          <VerifyEmail />
        </PublicRoute>
      }
    />
    <Route
      path="/login"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />
    <Route
      path="/forgot-password"
      element={
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      }
    />
  </>
);

export default AuthRoute;
