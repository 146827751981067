import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import style from "./popup-header.module.css";
import { TitleText } from "../text/text.component";

interface PopupHeaderProps {
  title: string;
  onClick?: () => void;
}

export function PopupHeader({ title, onClick }: PopupHeaderProps): JSX.Element {
  return (
    <div className={style.container}>
      <TitleText>{title}</TitleText>
      {onClick && (
        <div>
          <IconButton onClick={onClick}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}
