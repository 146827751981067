import React, { useState, useEffect, useMemo, useContext, BaseSyntheticEvent } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useForm } from "react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockResetIcon from "@mui/icons-material/LockReset";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import LogoutIcon from "@mui/icons-material/Logout";
import UserAvatar from "components/common/UserAvatar";
import { useAuth } from "hooks/useProvideAuth";
import { SIGN_OUT_DELAY } from "utils/constants";
import { clearDepotData } from "depot/map-page.depot";
import useConfirm from "hooks/useConfirm";
import logoNoText from "assets/svg/mega_portal_logo_no_text.svg";
import { onEnter } from "utils/general";
import { changePassword, updateMfaStatus, updateUserSetting } from "api/UserSettingHelpers";
import { showError, showSuccess, showWarning } from "utils/notifications";
import { ButtonText } from "components/common/Buttons";
import { Popup } from "components/common/popup/popup.component";
import { Spinner } from "components/common/spinner/spinner.component";
import { passwordStrengthCheck } from "utils/util-functions";
import { FieldError, PhoneInputWrapper, StyledTextField } from "components/common/Input";
import { GlobalContext } from "context/GlobalContext";
import { CommonText } from "components/common/text/text.component";
import { ContentRowComponent } from "./components/content-row.component";

import {
  MenuBarWrapper,
  DrawerButton,
  MailLink,
  ProfileContent,
  AvatarButton,
  MFASwitch,
  TitleWrapper,
  NewPasswordForm,
  MenuBarContent,
} from "./Menubar.style";

interface MenuBarProps {
  toggleDrawer: () => void;
}

const Menubar = ({ toggleDrawer }: MenuBarProps) => {
  const {
    name,
    email,
    signOut,
    refetch,
    phone_number: currPhoneNumber,
    profile: organizationName,
    roleName,
    isMFAEnabled: mfaEnabled,
    sub: userId,
  }: any = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>(currPhoneNumber);

  const { isDirectFromLogin, setIsDirectFromLogin }: any = useContext(GlobalContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  }: any = useForm({});
  const newPassword: string = watch("newPassword", "");

  const [getConfirmation, Confirmation] = useConfirm();
  const [isOpenProfileModal, setIsOpenProfileModal] = useState<boolean>(false);
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState<boolean>(false);
  const [isMFAEnabled, setIsMFAEnabled] = useState<boolean>(mfaEnabled);
  const [isSavingSetting, setIsSavingSetting] = useState<boolean>(false);
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const organizationTitle = useMemo(() => organizationName?.replace("-", " "), [organizationName]);

  useEffect(() => {
    if (mfaEnabled !== undefined) {
      setIsMFAEnabled(mfaEnabled);
    }
  }, [mfaEnabled]);

  const onClickAvatar = (event: BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (menuItem: string) => {
    setAnchorEl(null);
    if (menuItem === "logout") {
      // clears all depot data so it does not persist to the next user log in.
      clearDepotData();

      await signOut();
    }
  };

  const onToggleMFA = (event: BaseSyntheticEvent) => {
    const { target } = event;
    setIsMFAEnabled(target.checked);
  };

  const showProfileModal = () => {
    refetch();
    setIsMFAEnabled(mfaEnabled);
    setPhoneNumber(currPhoneNumber);
    setAnchorEl(null);
    setIsOpenProfileModal(true);
  };

  const onChangePassword = () => {
    setAnchorEl(null);
    setIsOpenPasswordModal(true);
    setValue("currPassword", null);
    setValue("newPassword", null);
    setValue("confirmNewPassword", null);
  };

  const saveUserSetting = async () => {
    // if phone number is not valid
    if (!phoneNumber || !isPossiblePhoneNumber(phoneNumber)) {
      return;
    }

    if (phoneNumber === currPhoneNumber && mfaEnabled === isMFAEnabled) {
      showWarning("Nothing changed!");
      return;
    }

    try {
      let updateMFA = false;
      let updatePhoneNumber = false;

      // if phone number is changed
      if (phoneNumber !== currPhoneNumber) {
        const isConfirm = await getConfirmation({
          title: "Phone Number Change",
          content: "Are you sure to update the phone number?",
          buttonTxt: ButtonText.Update,
        });

        if (isConfirm) {
          updatePhoneNumber = true;
          setIsOpenProfileModal(false);
        }
      }

      // if MFA setting is changed
      if (mfaEnabled !== isMFAEnabled) {
        const isConfirm = await getConfirmation({
          title: "MFA Setting Confirmation",
          content: `To ${isMFAEnabled ? "enable" : "disable"} MFA, you will be logged out. Would you like to continue?`,
          buttonTxt: ButtonText.Continue,
        });

        if (isConfirm) {
          updateMFA = true;
        }
      }

      if (updateMFA) {
        setIsSavingSetting(true);
        await updateMfaStatus({ userEmail: email, mfaEnabled: isMFAEnabled.toString() });
        showSuccess(`MFA setting is ${isMFAEnabled ? "enabled" : "disabled"} now!`);
      }
      if (updatePhoneNumber) {
        await updateUserSetting({ username: userId, email, phonenumber: phoneNumber });
        showSuccess(
          "Updated phone number successfully! You need to log out and log in again to see changes on the phone number.",
        );
      }

      setIsOpenProfileModal(false);
      if (updateMFA) {
        setTimeout(async () => {
          // clears all depot data so it does not persist to the next user log in.
          clearDepotData();

          await signOut();
        }, SIGN_OUT_DELAY);
      }
    } catch (e) {
      // error handling
    } finally {
      setIsSavingSetting(false);
    }
  };

  const onFormSubmit = async (data: { currPassword: string; newPassword: string }) => {
    setIsOpenPasswordModal(false);

    const { currPassword, newPassword: newPwd } = data;

    const accessToken = localStorage.getItem("access");
    if (!accessToken) {
      showError("Access token is not valid!");
      return;
    }

    const body = {
      username: userId,
      usertoken: accessToken,
      oldpassword: currPassword,
      newpassword: newPwd,
    };

    setIsChangingPassword(true);
    try {
      const { data: res } = await changePassword(body);
      showSuccess(res?.message);
      setIsOpenPasswordModal(false);
    } catch (error) {
      // error handling
    } finally {
      setIsChangingPassword(false);
    }
  };

  useEffect(() => {
    if (isDirectFromLogin) {
      setIsDirectFromLogin(false);
    }
  }, []);

  const getFieldError = () => {
    if (phoneNumber && !isPossiblePhoneNumber(phoneNumber)) {
      return <FieldError>Phone number is not valid!</FieldError>;
    }

    if (!phoneNumber) {
      return <FieldError>Phone number is required!</FieldError>;
    }
  };

  return (
    <>
      <MenuBarWrapper>
        <MenuBarContent>
          <DrawerButton onClick={() => toggleDrawer?.()}>
            <MenuIcon style={{ color: theme.palette.common.white }} />
          </DrawerButton>

          <TitleWrapper>
            <img src={logoNoText} alt="logo" style={{ height: 30 }} />
            <div className="title-text">{`Mega Portal - ${organizationTitle}`}</div>
          </TitleWrapper>

          <AvatarButton onClick={onClickAvatar}>
            <PersonOutlineIcon style={{ marginRight: 4 }} />
            <UserAvatar name={name} email={email} />
            <ArrowDropDownIcon style={{ marginBottom: 2 }} />
          </AvatarButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            disableScrollLock
          >
            <MenuItem onClick={showProfileModal}>
              <ListItemIcon>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText>User Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={onChangePassword}>
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              <ListItemText>Change Password</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setAnchorEl(null)}>
              <MailLink href="mailto:support@nextivityinc.com">
                <ListItemIcon>
                  <SettingsPhoneIcon />
                </ListItemIcon>
                <ListItemText>Contact Support</ListItemText>
              </MailLink>
            </MenuItem>
            <MenuItem onClick={() => handleClose("logout")}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </MenuBarContent>
      </MenuBarWrapper>

      <Popup
        open={isOpenPasswordModal}
        title="Change Password"
        primaryBtn={{ text: "Change", onClick: handleSubmit(onFormSubmit) }}
        secondaryBtn={{ text: "Cancel", onClick: () => setIsOpenPasswordModal(false) }}
      >
        <NewPasswordForm>
          <StyledTextField
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onFormSubmit))}
            type="password"
            label="Current Password"
            {...register("currPassword", {
              required: "Current Password is required!",
            })}
            error={errors.currPassword}
          />
          {errors.currPassword && <FieldError>{errors.currPassword.message}</FieldError>}

          <StyledTextField
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onFormSubmit))}
            type="password"
            label="New Password"
            {...register("newPassword", {
              required: "New Password is required!",
              validate: {
                passwordStrengthCheck,
              },
            })}
            error={errors.newPassword}
          />
          {errors.newPassword && <FieldError>{errors.newPassword.message}</FieldError>}

          <StyledTextField
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onFormSubmit))}
            type="password"
            label="Confirm New Password"
            {...register("confirmNewPassword", {
              required: "Confirm New Password is required!",
              validate: (value: string) => value === newPassword || "Passwords do not match!",
            })}
            error={errors.confirmNewPassword}
          />
          {errors.confirmNewPassword && <FieldError>{errors.confirmNewPassword.message}</FieldError>}
        </NewPasswordForm>
      </Popup>

      {/* TODO */}

      <Popup
        title="User Profile"
        open={isOpenProfileModal}
        primaryBtn={{ text: "Save", onClick: saveUserSetting, disabled: isSavingSetting }}
        secondaryBtn={{ text: "Cancel", onClick: () => setIsOpenProfileModal(false) }}
      >
        <ProfileContent>
          <ContentRowComponent label="Full Name:" value={name} />
          <ContentRowComponent label="Email:" value={email} />
          <ContentRowComponent label="Mobile Phone Number:">
            <PhoneInputWrapper
              international
              defaultCountry="US"
              countryCallingCodeEditable={false}
              value={phoneNumber}
              onChange={(e: string) => {
                setPhoneNumber(e);
              }}
            />
            {getFieldError()}
          </ContentRowComponent>
          <ContentRowComponent label="Organization Name:" value={organizationName} />
          <ContentRowComponent label="Role Name:" value={roleName} />
          <ContentRowComponent label="Multi-Factor Authentication:">
            <FormControlLabel
              control={<MFASwitch defaultChecked={isMFAEnabled} />}
              label={<CommonText>{isMFAEnabled ? "Enabled" : "Disabled"}</CommonText>}
              onChange={onToggleMFA}
            />
          </ContentRowComponent>
        </ProfileContent>
      </Popup>

      <Popup title="Updating password..." open={isChangingPassword}>
        <Spinner />
      </Popup>

      <Confirmation />
    </>
  );
};

export default Menubar;
