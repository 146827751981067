import React from "react";
import { Drawer } from "@mui/material";
import { MapSidebarContent } from "./map-sidebar-content.component";
import { SidebarWrapper } from "./MapSidebar.style";

interface MapSidebarProps {
  showDrawer?: boolean;
  onCloseDrawer?: () => void;
  dataFieldStr: string;
}

const MapSidebar = ({ showDrawer, onCloseDrawer, dataFieldStr }: MapSidebarProps): JSX.Element => (
  <div>
    <SidebarWrapper style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <MapSidebarContent dataFieldStr={dataFieldStr} />
    </SidebarWrapper>

    <Drawer
      anchor="right"
      open={showDrawer}
      onClose={() => onCloseDrawer?.()}
      ModalProps={{
        keepMounted: true,
      }}
    />
  </div>
);

export default MapSidebar;
