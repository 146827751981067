import React from "react";
import { OptionTypes } from "pages/configs/configs";
import MenuItem from "@mui/material/MenuItem";
import { Control, Controller, FieldValues } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import StyledSelect from "./Select.style";

interface ActionBarSelectProps {
  actionName: string;
  control: Control<FieldValues, any>;
  rules?: Record<string, string>;
  options: OptionTypes[];
  width?: string;
}

const ActionBarSelect = ({
  actionName,
  control,
  rules,
  options,
  width = "100%",
  ...props
}: ActionBarSelectProps): JSX.Element => (
  <Controller
    name={actionName}
    control={control}
    rules={rules}
    defaultValue={options?.[0]?.value}
    render={({ field }) => (
      <FormControl sx={{ width }} size="small">
        <StyledSelect {...field} {...props} inputProps={{ "aria-label": "Without label" }}>
          {options.map(({ value, label }) => (
            <MenuItem style={{ fontSize: "14px" }} key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    )}
  />
);

export default ActionBarSelect;
