import styled from "@emotion/styled";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell from "@mui/material/TableCell";
import BREAKPOINTS from "utils/breakpoints";

export const NoDataWrapper = styled(TableCell)`
  text-align: center;
  line-spacing: 0;
  letter-spacing: 0;
`;

export const DetailRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
  justify-content: space-between;

  &.start-align {
    align-items: flex-start;
  }

  span.label,
  span.results {
    font-size: 15px;
    margin-right: 12px;
    color: ${({ theme }) => theme.palette.gray[500]};
    display: flex;
    align-items: center;

    &.label {
      min-width: 116px;
      font-size: 14px;
    }

    &.results {
      font-weight: 400;
      color: ${({ theme }) => theme.palette.text.primary};
      font-size: 17px;
    }
  }

  span.error-text {
    margin-top: 10px;
    color: ${({ theme }) => theme.palette.error.main};
    white-space: pre-wrap;
    font-size: 13px;
    position: absolute;
    top: 27px;
  }

  .results-checkbox {
    display: flex;
    flex-direction: column;

    span.MuiFormControlLabel-label {
      color: ${({ theme }) => theme.palette.text.primary};
      font-size: 17px;
    }
  }

  .status-row {
    display: flex;
    align-items: center;
  }
`;

export const DetailFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const DetailGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 100px;

  ${BREAKPOINTS.lg} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }

  ${BREAKPOINTS.sm} {
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
  }
`;

export const GridActionRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  .header-title {
    font-weight: bold;
    font-size: 20px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);

  & > div {
    width: 50px;
    height: 50px;
  }
`;

export const StatusDot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 5px;
`;

export const FormLabel = styled(FormControlLabel)`
  .MuiCheckbox-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;
