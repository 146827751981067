import { styled } from "@mui/material/styles";
import style from "@emotion/styled";

import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";

import BREAKPOINTS from "utils/breakpoints";

export const TableWrapper = styled(TableContainer)`
  .hide-mobile {
    ${BREAKPOINTS.sm} {
      display: none;
    }
  }
`;

export const NoDataWrapper = styled(TableCell)`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const TableHeaderCell = styled(TableCell)`
  color: #aab2bd;
`;

export const TableBodyCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.text.primary};
  letter-spacing: 0;
  line-spacing: 0;
`;

export const FilterWrapper = style.div`
  display: flex;
  flex-direction: column;

  span.error-text {
    margin-top: 10px;
    color: #D73C23;
    white-space: pre-wrap;
    font-size: 13px;
    position: absolute;
    top: 27px;
    left: 0px;
    padding-left: 16px;
  }
`;

export const ActiveFilter = style.div`
  font-size: 13px;
`;

export const DateFilterWrapper = style.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input.MuiInputBase-input {
    height: 0px;
  }
`;
