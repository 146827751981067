import axiosInst from "./config/axiosConfig";

// delete local deviceData and HashTable
// Will perform a device reboot or a cloud UUID factory reset
export async function changeDeviceStatusFromUI(data) {
  const { eiSerialNumber } = data;
  if (!eiSerialNumber) return null;

  return axiosInst
    .put("/beta/eiStatusUIHook", {
      ...data,
    })
    .then((response) => response);
}

export async function deleteDevice(serialNumber) {
  return axiosInst.delete("/beta/devices", {
    params: {
      serialNumber,
    },
  });
}

export async function updateDevice(body) {
  return axiosInst.put("/beta/devices", body);
}

export async function updateVpnDeviceStatus(data) {
  return axiosInst.post("/beta/vpnUIHook", { serialNumber: data });
}

export async function disconnectDeviceVpn(serialNumber) {
  // requires 'data' object config
  return axiosInst.delete("/beta/vpnUIHook", { data: { serialNumber } });
}

export async function addSingleDeviceAPIHelper(deviceToAddObj) {
  const deviceObj = { ...deviceToAddObj };
  if (!deviceObj.mac || !deviceObj.serialNumber) {
    return null;
  }

  if (!deviceObj.devicePool) {
    deviceObj.devicePool = "default";
  }

  return axiosInst
    .post("/beta/devices", {
      ...deviceObj,
    })
    .then((response) => response);
}
