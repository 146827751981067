import { BehaviorSubject, type Observable } from "rxjs";

/**
 * Create a new StateProperty and assign it an initial value.
 *
 * A StateProperty can be statically accessed with getter and setters methods (
 * [getData()]{@link DepotBin.getData}/[setData()]{@link DepotBin.setData})
 *
 * or the [data]{@link DepotBin.data} property.
 *
 * A StateProperty can be dynamically accessed via
 * [RxJS]{@link https://rxjs.dev/} using the
 * [getObservable()]{@link DepotBin.getObservable} function or the
 * [observable]{@link DepotBin.observable} property.
 */
export class DepotBin<T> {
  // Event used to trigger the State Observable
  static readonly CHANGED_EVENT = "StateProperty changed";
  private readonly defaultValue_: T;
  private readonly subject_: BehaviorSubject<T>;

  constructor(defaultValue: T) {
    this.defaultValue_ = defaultValue;
    this.subject_ = new BehaviorSubject<T>(this.defaultValue_);
  }

  /**
   * See [getData]{@link DepotBin.getData}
   *
   * @example
   *
   * const dbDevice = stateService.connectedDevice.data;
   */
  get data(): T {
    return structuredClone(this.subject_.value);
  }

  /**
   * See [setData]{@link DepotBin.setData}
   *
   * @example
   *
   * stateService.connectedDevice.data = dbDevice;
   */
  set data(data: T) {
    this.subject_.next(data);
    document.dispatchEvent(new Event(DepotBin.CHANGED_EVENT));
  }

  /**
   * See [getObservable]{@link DepotBin.getObservable}
   *
   * @example
   *
   * // Prints the device every time the device changes.
   * stateService.connectedDevice.observable.subscribe({
   *       // This "device" is a unique instance of "device"
   *       next: (device) => console.log(device)
   * });
   */
  get observable(): Observable<T> {
    return this.subject_.asObservable();
  }

  /**
   * Get the data value.
   *
   * @example
   *
   * const dbDevice = stateService.connectedDevice.getData();
   */
  getData(): T {
    return this.data;
  }

  /**
   * Set the data value.
   *
   * *This will update all subscriptions.*
   *
   * @example
   *
   * stateService.connectedDevice.setData(dbDevice);
   */
  setData(data: T): void {
    this.data = data;
  }

  /**
   * An [RxJS]{@link https://rxjs.dev/} observable object that you can
   * subscribe to.
   *
   * @example
   *
   * const obs = stateService.connectedDevice.getObservable();
   *
   * // Prints the device every time the device changes.
   * obs.subscribe({
   *   // This "device" is a unique instance of "device"
   *   next: (device) => console.log(device)
   * });
   */
  getObservable(): Observable<T> {
    return this.observable;
  }

  /**
   * Reset the data back to its initial value.
   *
   * *This will update all subscriptions.*
   *
   * @example
   *
   * stateService.connectedDevice.reset();
   */
  reset() {
    this.data = this.defaultValue_;
  }
}
