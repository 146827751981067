import React from "react";
import Box from "@mui/material/Box";

import { Popup } from "components/common/popup/popup.component";
import { CommonText } from "components/common/text/text.component";
import { PrimaryButton, GhostButton } from "../common/Buttons";

const FormFactory = (function () {
  let instance = null;

  class FormSingleton {
    constructor() {
      this.handleCancel = undefined;
      this.handleSubmit = undefined;
      this.onSave = undefined;
      this.isEditing = undefined;
      this.Confirmation = undefined;
      this.isLongUpdate = undefined;

      // Going to turn off this type checking.  TODO: Should be revisited when we get to TypeScript
      // eslint-disable-next-line react/prop-types
      this.Form = ({ children, isLoading = false, closePopup, openPopup, isLongUpdate, isLongUpdatePopup }) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {children}

          {instance.isEditing && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
              <GhostButton onClick={instance.handleCancel}>Cancel</GhostButton>
              <PrimaryButton
                loading={isLoading}
                onClick={isLongUpdate ? openPopup : instance.handleSubmit(instance.onSave)}
              >
                Save
              </PrimaryButton>
            </Box>
          )}

          {isLongUpdatePopup && (
            <Popup
              isOpen={isLongUpdatePopup}
              title="Software Update Warning"
              primaryBtn={{ onClick: instance.handleSubmit(instance.onSave), text: "Continue" }}
              secondaryBtn={{ onClick: closePopup, text: "Cancel" }}
            >
              <CommonText>
                Important: This software update may take up to 15 minutes, depending on your connection speed. Please do
                not close the application or power off your device during this process.
              </CommonText>
            </Popup>
          )}

          <this.Confirmation />
        </Box>
      );
    }
  }

  return {
    getInstance: function ({ handleCancel, handleSubmit, onSave, isEditing, Confirmation }) {
      if (!instance) {
        instance = new FormSingleton();
        delete instance.constructor;
      }

      instance.handleCancel = handleCancel;
      instance.handleSubmit = handleSubmit;
      instance.onSave = onSave;
      instance.isEditing = isEditing;
      instance.Confirmation = Confirmation;

      return instance;
    },
  };
})();

export default FormFactory;
