import { deviceListCounter, pageCounter, poolData, poolDeviceList } from "depot/map-page.depot";
import { DEVICE_LIST_PAGE_SIZE, POOL_LIST_PAGE_SIZE } from "pages/Map/components/MapSidebar/MapSidebar.config";
import axiosInst from "./config/axiosConfig";

/**
 * @param {*} devicePoolToAddObj {
 devicePool      string
 orgID           string
 defaultConfigID string
 deviceCount     int
 }
 * @returns {
 devicePool      string
 orgID           string
 defaultConfigID string
 deviceCount     int
 }
 */
export async function createDevicePoolAPIHelper(devicePoolToAddObj) {
  const devicePoolObj = { ...devicePoolToAddObj };
  if (devicePoolObj.orgID === undefined || devicePoolObj.orgID === null) {
    return null;
  }
  if (devicePoolObj.devicePool === undefined || devicePoolObj.devicePool === null) {
    devicePoolObj.devicePool = "default";
  }
  devicePoolObj.deviceCount = 0;

  return axiosInst
    .post("/beta/devicePools", {
      ...devicePoolObj,
    })
    .then((response) => response);
}

export async function getDevicePools() {
  const paginationCount = pageCounter.data;
  const firstIndex = POOL_LIST_PAGE_SIZE * paginationCount;
  const lastIndex = firstIndex + POOL_LIST_PAGE_SIZE - 1;

  const response = await axiosInst.get(
    `/beta/devicePools?firstDevicePoolIndex=${firstIndex}&lastDevicePoolIndex=${lastIndex}`,
  );

  const combinedArray = poolData.data.devicePoolData.concat(response.data.devicePoolData);

  poolData.setData({
    devicePoolData: !combinedArray.includes(null) ? combinedArray : [],
    totalDevicePoolsInOrg: response.data.totalDevicePoolsInOrg,
  });
}

export async function getAllDevicePools() {
  return axiosInst.get("/beta/devicePools");
}

export async function getAllDeviceInPool(poolName) {
  return axiosInst.get(`/beta/devices?devicePool=${poolName}`);
}

export async function createNewPool(poolName, orgId, defaultConfigId = "") {
  return axiosInst.post("/beta/devicePools", {
    devicePool: poolName,
    orgID: orgId,
    defaultConfigId: defaultConfigId,
  });
}

export async function updateDevicePool(body) {
  return axiosInst.put("/beta/devicePools", body);
}

export async function deleteDevicePool(poolName) {
  return axiosInst.delete(`/beta/devicePools?devicePool=${poolName}`);
}

export async function getDeviceInPool(poolId) {
  const paginationCount = deviceListCounter.data;
  const startIndex = DEVICE_LIST_PAGE_SIZE * paginationCount[poolId];
  const lastIndex = startIndex + DEVICE_LIST_PAGE_SIZE - 1;

  const response = await axiosInst.get(
    `/beta/devices?devicePool=${poolId}&firstDeviceIndex=${startIndex}&lastDeviceIndex=${lastIndex}`,
  );

  const deviceList = { ...poolDeviceList.data };
  const fetchedDevices = response.data.deviceData;

  if (!fetchedDevices) {
    return;
  }

  let isReachingEnd = fetchedDevices.length <= DEVICE_LIST_PAGE_SIZE;

  const deviceData = { devices: fetchedDevices, isReachingEnd };

  if (!(poolId in poolDeviceList.data)) {
    deviceList[poolId] = deviceData;
    poolDeviceList.setData(deviceList);
  } else {
    const concatArray = deviceList[poolId].devices.concat(deviceData.devices);
    isReachingEnd = fetchedDevices.length <= DEVICE_LIST_PAGE_SIZE || !fetchedDevices;

    deviceList[poolId] = { devices: concatArray, isReachingEnd };
  }
  poolDeviceList.setData(deviceList);
}
