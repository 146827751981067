import React, { ReactNode, useState } from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { DeviceDetailDataTypes, DeviceValueTypes } from "pages/Devices/devices.config";
import { PoolDeviceTypes } from "pages/Pools/pools.config";
import { SORT_DIRECTION } from "utils/constants";
import LoadingSpinner from "components/LoadingSpinner/loading-spinner.component";
import { UserListTypes } from "pages/Users/users.config";
import { TableColumnTypes } from "pages/configs/configs";
import { TableWrapper, NoDataWrapper, TableHeaderCell } from "./table.style";

interface CustomTableProps {
  children: ReactNode;
  rowsPerPageOptions?: number[];
  totalCount?: number;
  onPageChange?: (arg: number) => void;
  page?: number;
  onRowsPerPageChange?: (arg: number) => void;
  rowsPerPage?: number;
  boxShadow?: boolean;
}

const CustomTable = ({
  children,
  rowsPerPageOptions,
  totalCount,
  onPageChange,
  boxShadow,
  page,
  onRowsPerPageChange,
  rowsPerPage,
  ...rest
}: CustomTableProps): JSX.Element => {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPageChange?.(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    onRowsPerPageChange?.(+event.target.value);
    onPageChange?.(0);
  };

  const getTablePagination = (): JSX.Element => {
    if (!totalCount || !rowsPerPage || page === undefined || !rowsPerPageOptions) {
      return <></>;
    }

    return (
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );
  };

  return (
    <Paper
      style={boxShadow ? { boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" } : {}}
      sx={{ width: "100%", overflow: "hidden", position: "relative", boxShadow: 0 }}
    >
      <TableWrapper>
        <Table sx={{ boxShadow: 0 }} stickyHeader aria-label="sticky table" {...rest}>
          {children}
        </Table>
      </TableWrapper>

      {getTablePagination()}
    </Paper>
  );
};

interface CustomTableHeaderProps {
  columns: TableColumnTypes[];
  onRequestSort?: (order: string, orderBy: string) => void;
}

const CustomTableHeader = ({ columns, onRequestSort, ...rest }: any): JSX.Element => {
  const [order, setOrder] = useState<"desc" | "asc" | undefined>(SORT_DIRECTION.ASC);
  const [orderBy, setOrderBy] = useState<string>();

  const createSortHandler = (property: string) => () => {
    // update internal state
    const isAsc = orderBy === property && order === SORT_DIRECTION.ASC;
    setOrder(isAsc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC);
    setOrderBy(property);

    // sent event to outside
    onRequestSort?.(isAsc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC, property);
  };

  return (
    <TableHead {...rest}>
      <TableRow>
        {columns.map(({ id, className, align, width, label, isSort }: any) => (
          <TableHeaderCell className={className} key={id} align={align}>
            {id && label && isSort ? (
              <TableSortLabel
                sx={{ width: width }}
                active={orderBy === id}
                direction={orderBy === id ? order : SORT_DIRECTION.ASC}
                onClick={createSortHandler(id)}
              >
                {label}
              </TableSortLabel>
            ) : (
              <div>{label}</div>
            )}
          </TableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface CustomTableBodyProps {
  children: ReactNode;
  isLoading: boolean;
  deviceData?: DeviceValueTypes[] | DeviceDetailDataTypes[] | UserListTypes[] | PoolDeviceTypes[];
}

const CustomTableBody = ({ children, isLoading, deviceData, ...rest }: CustomTableBodyProps): JSX.Element => (
  <TableBody sx={{ boxShadow: 0 }} {...rest}>
    {children}
    {!isLoading && !deviceData?.length && (
      <TableRow>
        <NoDataWrapper colSpan={12} style={{ height: 150 }}>
          No records found!
        </NoDataWrapper>
      </TableRow>
    )}
    {isLoading && (
      <TableRow>
        <NoDataWrapper colSpan={12} style={{ height: 150, position: "relative" }}>
          <LoadingSpinner />
        </NoDataWrapper>
      </TableRow>
    )}
  </TableBody>
);

CustomTable.Header = CustomTableHeader;

CustomTable.Body = CustomTableBody;

export default CustomTable;
