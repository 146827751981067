import styled from "@emotion/styled";
import BREAKPOINTS from "utils/breakpoints";

export const MapPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RouteToolsHelpContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const RouteToolsButtonsWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  padding-top: 8px;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  .leaflet-right > .leaflet-routing-container-hide {
    display: none;
  }

  &.dark {
    .leaflet-layer,
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    .leaflet-control-attribution {
      filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
    }
  }
`;

export const MarkerWrapper = styled.div`
  position: relative;
  z-index: 1010;
  padding: 0px 0px 10px 0px;
  margin-left: 12px;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: 1;
`;

export const PageTitle = styled.div`
  font-size: 36px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: var(--font-light);
  margin-bottom: 7px;

  ${BREAKPOINTS.md} {
    display: none;
  }
`;
