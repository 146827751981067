import { COLUMN_TYPE } from "utils/constants";
import { getDateString } from "utils/util-functions";

export interface OrgTableRowTypes {
  CreationDate: string;
  GroupName: string;
  LastModifiedDate: string;
  UserPoolId: string;
}

export const orgTableRowDefaults = {
  CreationDate: "",
  GroupName: "",
  LastModifiedDate: "",
  UserPoolId: "",
};

export interface ORG_COLUMN_TYPES {
  id: string;
  label: string;
  filter?: boolean;
  isSort?: boolean;
  type?: string;
  format?: (arg: any) => string;
}

const ORG_COLUMNS: ORG_COLUMN_TYPES[] = [
  {
    id: "GroupName",
    label: "Organization Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "CreationDate",
    label: "Created",
    filter: true,
    isSort: true,
    format: (value: any) => getDateString(value || ""),
    type: COLUMN_TYPE.DATE,
  },
  {
    id: "LastModifiedDate",
    label: "Last Modified",
    filter: true,
    isSort: true,
    format: (value: any) => getDateString(value || ""),
    type: COLUMN_TYPE.DATE,
  },
  { id: "action-col", label: "" },
];

export default ORG_COLUMNS;
