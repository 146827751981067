import React from "react";
import { Checkbox } from "@mui/material";
import { CommonText } from "components/common/text/text.component";
import { IconLabelWrapper, TreeLabelWrapper } from "./MapSidebar.style";

interface CheckableLabelProps {
  title: string;
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
}

export const CheckableLabel = ({ title, onCheck, isChecked }: CheckableLabelProps): JSX.Element => (
  <IconLabelWrapper
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <Checkbox onChange={onCheck} checked={isChecked} />
    <TreeLabelWrapper>
      <CommonText>{title}</CommonText>
    </TreeLabelWrapper>
  </IconLabelWrapper>
);
