export const letterNumberRegex = /^[A-Za-z0-9 ]*$/;
export const letterNumberUnderscoreRegex = /^[A-Za-z0-9-_ ]*$/;
export const letterNumberUnderscoreNoSpaceRegex = /^[A-Za-z0-9-_]*$/;
export const onlyStartWithLetterRegex = /^[a-zA-Z]/;
export const onlyStartWithLetterNumberRegex = /^[a-zA-Z0-9]/;
export const startWithLetterRegex = /^[A-Za-z][A-Za-z0-9 ]*$/;
export const phoneNumberRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
export const emailRegex = /\S+@\S+\.\S+/;
export const min8Length = /^.{8,}$/;
export const oneUpperCase = /^(?=.*[A-Z]).{1,}$/;
export const oneLowerCase = /^(?=.*[a-z]).{1,}$/;
export const oneSpecialLetter = /^(?=.*[!@#$&*]).{1,}$/;
export const oneNumber = /^(?=.*[0-9]).{1,}$/;
export const firstCharacterNotSpecialCharacterRegex = /^[^!#;].*$/;
export const notIncludeSpecialCharacters = /^[^+\]/"\t]+$/;
export const trailingSpace = /[ ]+$/;
export const ipv4Address = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

export interface SortDirectionTypes {
  ASC: "asc";
  DESC: "desc";
}

export const SORT_DIRECTION: SortDirectionTypes = {
  ASC: "asc",
  DESC: "desc",
};

export const COLOR_MODE = {
  LIGHT: "light",
  DARK: "dark",
};

export const USER_TYPE = {
  WEB_USER: "webuser",
  ADMIN: "admin",
  POWER_USER: "poweruser",
};

export const REFRESH_INTERVAL: number = 60 * 1000; // 1 min

export const ERROR_RETRY_COUNT: number = 0;

export const SIGN_OUT_DELAY: number = 2000;

export const MAPBOX_STYLE_ID: string = "streets-v12";

export interface ColumnTypes {
  STRING: string;
  OPTION: string;
  DATE: string;
  NUMBER: string;
}

export const COLUMN_TYPE: ColumnTypes = {
  STRING: "string",
  OPTION: "option",
  DATE: "date",
  NUMBER: "number",
};

export const SUPPORT_USERNAME = "Nextivity Support";
