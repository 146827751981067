import React, { forwardRef } from "react";
import { TextField, TextFieldProps } from "@mui/material";

const PhoneInput = (props: TextFieldProps, ref: React.ForwardedRef<TextFieldProps>) => (
  <TextField
    {...props}
    inputRef={ref}
    fullWidth
    size="small"
    label="Mobile Phone Number"
    variant="outlined"
    name="phone"
  />
);
export default forwardRef(PhoneInput);
