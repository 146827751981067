import React, { PropsWithChildren } from "react";
import { CommonText, BoldText } from "components/common/text/text.component";
import style from "./table-row.module.css";

interface TableRowProps extends PropsWithChildren {
  label: string;
  result: string | undefined;
}

export function TableRow({ label, result, children }: TableRowProps): JSX.Element {
  return (
    <div className={style.container}>
      <div className={style.label}>
        <CommonText>{label}</CommonText>
      </div>
      <div className={style.result}>{children || <BoldText>{result}</BoldText>}</div>
    </div>
  );
}
