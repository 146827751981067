import React, { useEffect, useState, useMemo } from "react";
import PersonAddOutlined from "@mui/icons-material/PersonAddAlt1Outlined";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { FieldValues, useForm, useFormState } from "react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, useTheme } from "@mui/material";
import { PageTitle } from "components/common/Text";
import { useAuth } from "hooks/useProvideAuth";
import { getUserGroup, updateUserSetting } from "api/UserSettingHelpers";
import { createNewUser, deleteUser, getUserListByGroup, UserListByGroupResponseTypes } from "api/AdminHelpers";
import { TableBodyCell } from "components/table/table.style";
import { FieldError, PhoneFormInputWrapper, StyledTextField } from "components/common/Input";
import { Popup } from "components/common/popup/popup.component";
import {
  convertCognitoAttr,
  getCurrentPage,
  getFilteredListNew,
  getSortList,
  nameValidationCheck,
} from "utils/util-functions";
import { CommonSelect } from "components/common/common-select/common-select.component";
import { onEnter } from "utils/general";
import { Spinner } from "components/common/spinner/spinner.component";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import TableFilterNew from "components/table/TableFilterNew.component";
import { SUPPORT_USERNAME, emailRegex } from "utils/constants";
import CustomTable from "components/table/table.component";
import { showSuccess } from "utils/notifications";
import { getSupportUserInfo } from "api/PowerUserHelpers";
import { ButtonText } from "components/common/Buttons";
import useConfirm from "hooks/useConfirm";
import UserPermissions from "components/table/user-permissions/user-permissions.component";
import { USER_COLUMNS, ROLE_OPTIONS, UserListTypes } from "./users.config";
import CustomPhoneNumber from "../../components/common/phone-input/phone-input.component";
import style from "./users.page.module.css";

const rowDefaultValues: UserListTypes = {
  Enabled: false,
  UserCreateDate: "",
  UserLastModifiedDate: "",
  UserStatus: "",
  Username: "",
  ["custom:Org_role"]: "",
  ["custom:Org_verified"]: "",
  email: "",
  email_verified: "",
  isAdmin: false,
  isPowerUser: false,
  name: "",
  organizationName: "",
  phone_number: "",
  phone_number_verified: "",
  profile: "",
  roleName: "",
  sub: "",
};

interface BaseSyntheticEvent<E = object, C = any, T = any> {
  nativeEvent: E;
  currentTarget: C;
  target: T;
  bubbles: boolean;
  cancelable: boolean;
  defaultPrevented: boolean;
  eventPhase: number;
  isTrusted: boolean;
  preventDefault(): void;
  isDefaultPrevented(): boolean;
  stopPropagation(): void;
  isPropagationStopped(): boolean;
  persist(): void;
  timeStamp: number;
  type: string;
}

export default function Users(): JSX.Element {
  const { sub: userId }: Record<string, string> = useAuth();
  const [userList, setUserList] = useState<UserListTypes[]>([]);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [isOpenNewUserModal, setIsOpenNewUserModal] = useState<boolean>(false);
  const [isOpenUpdateUserModal, setIsOpenUpdateUserModal] = useState<boolean>(false);
  const [phoneNum, setPhoneNum] = useState<string>("");
  const [currGroupName, setCurrGroupName] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = useState<UserListTypes>({ ...rowDefaultValues });
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [filterColumn, setFilterColumn] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");
  const [filterWord, setFilterWord] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [isPermissionAllowed, setIsPermissionAllowed] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("Creating new user...");

  const theme = useTheme();
  const [getConfirmation, Confirmation] = useConfirm();

  const filterColumns = useMemo(() => USER_COLUMNS.filter(({ filter }) => filter), [USER_COLUMNS]);

  const filteredList = useMemo(
    () => getFilteredListNew(userList, filterColumn, filterType, filterWord),
    [userList, filterColumn, filterWord, filterType],
  );

  const sortedList = useMemo(() => {
    const sortColumn = USER_COLUMNS.find(({ id }) => id === sortBy);
    return getSortList(filteredList, sortBy, sortOrder, sortColumn?.type);
  }, [sortBy, sortOrder, filteredList]);

  const paginatedList: UserListTypes[] = useMemo(
    () => getCurrentPage(sortedList, page, rowsPerPage),
    [sortedList, page, rowsPerPage],
  );

  const totalUserCount = useMemo(() => filteredList?.length || 0, [filteredList]);

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  }: FieldValues = useForm();
  const formState = useFormState({ control });

  const {
    handleSubmit: handleSubmitUpdate,
    formState: { errors: errorsUpdate },
    control: controlUpdate,
    setValue,
  }: FieldValues = useForm();
  const formStateUpdate = useFormState({ control: controlUpdate });

  const userColumns = useMemo(() => {
    const columns = [...USER_COLUMNS];
    columns.pop();
    return columns;
  }, []);

  const fetchUserGroup = async (): Promise<void> => {
    try {
      setIsLoadingList(true);

      const { data: userGroup } = await getUserGroup({ username: userId });

      if (userGroup?.length > 0) {
        const { GroupName: groupName } = userGroup[0];
        setCurrGroupName(groupName);

        const fetchUserListByGroup = async (token?: string) => {
          try {
            const { data } = await getUserListByGroup({ username: userId, groupname: groupName, token });
            if (token) {
              setUserList((value: UserListTypes[]) => [
                ...value,
                ...(data.Users?.map(({ Attributes, ...rest }: UserListByGroupResponseTypes) => {
                  const userData = convertCognitoAttr(Attributes);
                  return {
                    ...userData,
                    ...rest,
                  };
                }) || []),
              ]);
            } else {
              setUserList(
                data.Users?.map(({ Attributes, ...rest }: UserListByGroupResponseTypes) => {
                  const userData = convertCognitoAttr(Attributes);
                  return {
                    ...userData,
                    ...rest,
                  };
                }),
              );
            }

            if (data.NextToken) {
              await fetchUserListByGroup(data.NextToken);
            }
          } catch (error) {
            console.warn("Error", error);
          }
        };

        fetchUserListByGroup();
      }
    } catch (error) {
      // Error handling
    } finally {
      setIsLoadingList(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserGroup();
    }
  }, [userId]);

  useEffect(() => {
    // Reset the text field values for new user input
    const defaultValues: { userName: string; email: string } = {
      userName: "",
      email: "",
    };
    defaultValues.userName = "";
    defaultValues.email = "";
    reset({ ...defaultValues });
  }, [isOpenNewUserModal]);

  const onAddNewUser = async ({ userName, role, email }: FieldValues): Promise<void> => {
    setIsOpenNewUserModal(false);

    setIsLoadingUser(true);
    setModalMessage("Creating User...");

    if (!currGroupName) return;
    if (!phoneNum || !isPossiblePhoneNumber(phoneNum)) return;

    try {
      await createNewUser({
        groupname: currGroupName,
        name: userName,
        email,
        phonenumber: phoneNum,
        role,
        username: userId,
      });
      setIsLoadingUser(false);
      showSuccess("New User created successfully!");
      fetchUserGroup();
    } catch (error) {
      console.warn("error", error);
      setIsLoadingUser(false);
    }
  };

  const onNewUser = () => {
    setIsOpenNewUserModal(true);
    setPhoneNum("");
  };

  const onClickMenu = (event: BaseSyntheticEvent, row: UserListTypes) => {
    if (event === undefined) {
      return;
    }

    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const onDeleteUser = async () => {
    setAnchorEl(null);

    setModalMessage("Deleting user...");

    const isConfirm = await getConfirmation({
      buttonTxt: ButtonText.Delete,
      title: "Delete User?",
      content:
        "If this user has devices in their pool, transfer them to another pool before deleting. Do you want to delete this user?",
    });

    if (isConfirm) {
      setIsLoadingUser(true);
      const { email } = currentRow;

      setIsLoadingUser(true);
      try {
        await deleteUser({ userEmail: email, username: userId });
        showSuccess("Deleted user successfully!");

        if (userId) {
          fetchUserGroup();
        }
        setIsLoadingUser(false);
      } catch (error) {
        // error handling
      }
    }
  };

  const onUpdateUser = async ({ role }: FieldValues): Promise<void> => {
    if (!phoneNum || !isPossiblePhoneNumber(phoneNum)) return;

    setIsOpenUpdateUserModal(false);

    setIsLoadingUser(true);
    setModalMessage("Updating User...");

    if (currentRow === null) {
      return;
    }

    try {
      await updateUserSetting({
        username: userId,
        email: currentRow.email,
        phonenumber: phoneNum,
        role,
      });

      setIsLoadingUser(false);
      showSuccess("Updated user successfully!");
      fetchUserGroup();
      setIsOpenUpdateUserModal(false);
    } catch (error) {
      // error handling
      console.warn("error", error);
    }
  };

  const showUpdateModal = () => {
    setAnchorEl(null);

    if (currentRow) {
      const phoneNumber: string = currentRow?.phone_number;
      const roleName: string = currentRow?.roleName;

      setPhoneNum(phoneNumber);
      setValue("role", roleName);
      setIsOpenUpdateUserModal(true);
    }
  };

  const onSearch = (column: string, columnType: string, searchContent: string) => {
    setFilterColumn(column);
    setFilterWord(searchContent);
    setFilterType(columnType);
    setPage(0);
  };

  const onResetFilter = () => {
    setFilterColumn("");
    setFilterWord("");
    setFilterType("");
    setPage(0);
  };

  const handleRequestSort = (order: string, orderBy: string) => {
    setSortOrder(order);
    setSortBy(orderBy);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGrantSupport = async (row: UserListTypes, allow: boolean = true) => {
    await getSupportUserInfo({
      username: userId,
      supportname: SUPPORT_USERNAME,
      allow: allow.toString(),
      email: row.email,
    });
    setIsPermissionAllowed(allow);

    showSuccess(
      allow
        ? `Successfully allowed access to ${SUPPORT_USERNAME}`
        : `Successfully revoked access from ${SUPPORT_USERNAME}`,
    );
  };

  const borderColor = {
    borderTop: "2px solid #4FAEE0",
    borderBottom: "2px solid #4FAEE0",
  };

  const rightBorderColor = {
    border: "2px solid #4FAEE0",
    borderLeft: "none",
  };

  const leftBorderColor = {
    border: "2px solid #4FAEE0",
    borderRight: "none",
  };

  const styleRow = (name: string, columnId: number) => {
    if (name === SUPPORT_USERNAME) {
      if (columnId === 0) {
        return leftBorderColor;
      }

      return borderColor;
    }
    return {};
  };

  return (
    <>
      <PageTitle>Users</PageTitle>
      <div className={style.barContainer}>
        <div className={style.filterContainer}>
          {userList?.length > 10 ? (
            <TableFilterNew columns={filterColumns} onSearch={onSearch} onReset={onResetFilter} />
          ) : (
            <div />
          )}
        </div>

        <div className={style.buttonOptions}>
          <NxtIconButton onClick={onNewUser} text="New User" disabled={!currGroupName} icon={<PersonAddOutlined />} />
        </div>
      </div>
      <CustomTable
        boxShadow
        rowsPerPageOptions={[10, 25, 100]}
        totalCount={totalUserCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(pageNum) => setPage(pageNum)}
        onRowsPerPageChange={(rowPerPage: number) => setRowsPerPage(rowPerPage)}
      >
        <CustomTable.Header columns={USER_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={isLoadingList} deviceData={paginatedList}>
          {paginatedList &&
            paginatedList.map((row: UserListTypes) => {
              const { Username }: UserListTypes = row;

              return (
                row && (
                  <TableRow key={Username}>
                    {userColumns.map((column, columnId) => {
                      const value = row[column.id as keyof typeof row];

                      return (
                        <TableBodyCell sx={styleRow(row.name, columnId)} key={column.id}>
                          {column.format ? column.format(value) : value}
                        </TableBodyCell>
                      );
                    })}
                    <TableBodyCell style={{ width: 30 }} sx={row.name === SUPPORT_USERNAME ? rightBorderColor : {}}>
                      {row.name === SUPPORT_USERNAME ? (
                        <UserPermissions
                          row={row}
                          isPermissionAllowed={isPermissionAllowed}
                          handleGrantSupport={handleGrantSupport}
                        />
                      ) : (
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          onClick={(e: BaseSyntheticEvent) => onClickMenu(e, row)}
                          style={{ color: theme.palette.common.black }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </TableBodyCell>
                  </TableRow>
                )
              );
            })}
        </CustomTable.Body>
      </CustomTable>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={showUpdateModal}>Edit</MenuItem>
        <MenuItem onClick={onDeleteUser}>Delete</MenuItem>
      </Menu>

      <Popup
        open={isOpenNewUserModal}
        primaryBtn={{ text: "Create", onClick: handleSubmit(onAddNewUser) }}
        secondaryBtn={{ text: "Cancel", onClick: () => setIsOpenNewUserModal(false) }}
        title="New User"
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <StyledTextField
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onAddNewUser))}
            label="Full Name"
            {...register("userName", {
              required: "Full Name is required",
              maxLength: 50,
              validate: {
                nameValidationCheck,
              },
            })}
            error={errors.userName}
          />
          {errors.userName && (
            <FieldError theme={undefined}>
              {errors.userName.type === "maxLength"
                ? "Length of User Name should not exceed 50."
                : errors.userName.message}
            </FieldError>
          )}

          <CommonSelect
            name="role"
            label="Role"
            options={ROLE_OPTIONS}
            control={control}
            rules={{ required: "Role is required" }}
            error={errors.role}
          />
          {errors.role && <FieldError>{errors.role.message}</FieldError>}

          <StyledTextField
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onAddNewUser))}
            label="Email"
            {...register("email", {
              required: "Email is required!",
              pattern: {
                value: emailRegex,
                message: "Entered value does not match email format!",
              },
            })}
            error={errors.email}
          />
          {errors.email && <FieldError theme={undefined}>{errors.email.message}</FieldError>}

          <PhoneFormInputWrapper
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onAddNewUser))}
            international
            inputComponent={CustomPhoneNumber}
            defaultCountry="US"
            countryCallingCodeEditable={false}
            value={phoneNum}
            onChange={(e: string) => {
              setPhoneNum(e);
            }}
          />
          {formState?.submitCount > 0 && (
            <FieldError theme={undefined}>
              {phoneNum
                ? isPossiblePhoneNumber(phoneNum)
                  ? undefined
                  : "Phone number is not valid!"
                : "Phone number is required!"}
            </FieldError>
          )}
        </Box>
      </Popup>

      <Popup
        title={`Update User "${currentRow?.name}"`}
        primaryBtn={{ onClick: handleSubmitUpdate(onUpdateUser), text: "Update" }}
        secondaryBtn={{ onClick: () => setIsOpenUpdateUserModal(false), text: "Cancel" }}
        open={isOpenUpdateUserModal}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CommonSelect
            name="role"
            label="Role"
            options={ROLE_OPTIONS}
            control={controlUpdate}
            rules={{ required: "Role is required" }}
            error={errors.role}
          />
          {errorsUpdate.role && <FieldError>{errorsUpdate.role.message}</FieldError>}

          <PhoneFormInputWrapper
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmitUpdate(onUpdateUser))}
            international
            inputComponent={CustomPhoneNumber}
            defaultCountry="US"
            countryCallingCodeEditable={false}
            value={phoneNum}
            onChange={(e: string) => setPhoneNum(e)}
          />
          {formStateUpdate?.submitCount > 0 && (
            <FieldError theme={undefined}>
              {phoneNum
                ? isPossiblePhoneNumber(phoneNum)
                  ? undefined
                  : "Phone number is not valid!"
                : "Phone number is required!"}
            </FieldError>
          )}
        </Box>
      </Popup>

      <Confirmation />

      <Popup title={modalMessage} open={isLoadingUser}>
        <Spinner />
      </Popup>
    </>
  );
}
