import { getDateString, formatPhoneNumber } from "utils/util-functions";
import { COLUMN_TYPE, USER_TYPE } from "utils/constants";
import { OptionTypes, TableColumnTypes, UserColumnTypes } from "pages/configs/configs";

export interface UserListTypes {
  Enabled: boolean;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
  Username: string;
  ["custom:Org_role"]: string;
  ["custom:Org_verified"]: string;
  email: string;
  email_verified: string;
  isAdmin: boolean;
  isPowerUser: boolean;
  name: string;
  organizationName: string;
  phone_number: string;
  phone_number_verified: string;
  profile: string;
  roleName: string;
  sub: string;
}

const USER_COLUMNS: UserColumnTypes[] = [
  {
    id: "name",
    label: "Name",
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "email",
    label: "Email",
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "phone_number",
    label: "Mobile Phone Number",
    format: (value: string) => formatPhoneNumber(value || ""),
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "profile",
    label: "Organization",
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "roleName",
    label: "Role",
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.OPTION,
    options: [
      { value: USER_TYPE.ADMIN, label: "Admin" },
      { value: USER_TYPE.WEB_USER, label: "Web User" },
    ],
  },
  {
    id: "UserCreateDate",
    label: "Created",
    format: (value: string) => getDateString(value || ""),
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.DATE,
  },
  {
    id: "UserLastModifiedDate",
    label: "Updated",
    format: (value: string) => getDateString(value || ""),
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.DATE,
  },
  { id: "action-col", label: "" },
];

const ROLE_OPTIONS = [
  { value: USER_TYPE.WEB_USER, label: "Web User" },
  { value: USER_TYPE.ADMIN, label: "Admin" },
];

export { USER_COLUMNS, ROLE_OPTIONS };
