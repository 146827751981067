import { AttributesTypes, CustomAttribTypes, UserColumnTypes } from "pages/configs/configs";

import { COLUMN_TYPE } from "utils/constants";
import { formatPhoneNumber, getDateString } from "utils/util-functions";

export interface AdminListTypes {
  Attributes: CustomAttribTypes[];
  Enabled: boolean;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
  Username: string;
  ["custom:Org_role"]: string;
  ["custom:Org_verified"]: string;
  email: string;
  email_verified: string;
  isAdmin: boolean;
  isPowerUser: boolean;
  name: string;
  organizationName: string;
  phone_number: string;
  phone_number_verified: string;
  profile: string;
  roleName: string;
  sub: string;
}

const ADMIN_COLUMNS: UserColumnTypes[] = [
  {
    id: "name",
    label: "Name",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "profile",
    label: "Organization Name",
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "roleName",
    label: "Role",
  },
  {
    id: "phone_number",
    label: "Phone Number",
    format: (value: string) => formatPhoneNumber(value || ""),
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "email",
    label: "Email",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "UserStatus",
    label: "User Status",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "UserCreateDate",
    label: "Created",
    format: (value: string) => getDateString(value || ""),
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.DATE,
  },
  {
    id: "UserLastModifiedDate",
    label: "Last Modified",
    format: (value: string) => getDateString(value || ""),
    isSort: true,
    filter: true,
    type: COLUMN_TYPE.DATE,
  },
  { id: "action-col", label: "" },
];

export default ADMIN_COLUMNS;
