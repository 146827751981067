import React, { ReactNode } from "react";
import style from "./error-screen.module.css";

interface ErrorScreenProps {
  text: string;
}

export function ErrorScreen({ text = "Errors occurred while calling the API." }: ErrorScreenProps): JSX.Element {
  return <div className={style.container}>{text}</div>;
}
