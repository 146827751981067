import React from "react";
import { UserListTypes } from "pages/Users/users.config";
import { PrimaryButton } from "../../common/Buttons";

interface UserPermissionsProps {
  row: UserListTypes;
  isPermissionAllowed: boolean;
  handleGrantSupport: (row: UserListTypes, trueFalse: boolean) => void;
}

export default function UserPermissions({
  row,
  isPermissionAllowed,
  handleGrantSupport,
}: UserPermissionsProps): JSX.Element {
  if (!row.Enabled && !isPermissionAllowed) {
    return <PrimaryButton onClick={() => handleGrantSupport(row, true)}>ALLOW</PrimaryButton>;
  }
  return <PrimaryButton onClick={() => handleGrantSupport(row, false)}>REVOKE</PrimaryButton>;
}
