import React, { useEffect } from "react";
import { Checkbox } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { getDeviceMapPoints } from "api/MappingHelpers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { storeActiveSerial } from "utils/util-functions";
import InputIcon from "@mui/icons-material/Input";
import { CommonText } from "components/common/text/text.component";
import { DevicePageLink, IconLabelWrapper } from "./MapSidebar.style";

interface DeviceItemLabelProps {
  title?: string | boolean | undefined;
  serialNumber: string;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void | undefined;
  isChecked?: boolean;
  dataFieldStr: string;
  description?: string;
}

export const DeviceItemLabel = ({
  title,
  serialNumber,
  onCheck,
  isChecked,
  dataFieldStr,
  description,
}: DeviceItemLabelProps): JSX.Element => {
  const getData = async (): Promise<void> => {
    await getDeviceMapPoints(serialNumber, dataFieldStr, isChecked);
  };

  useEffect(() => {
    getData();
  }, [isChecked]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <IconLabelWrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Checkbox
        onClick={() => {
          storeActiveSerial(serialNumber, isChecked);
        }}
        onChange={onCheck}
        checked={isChecked}
      />

      <CommonText>
        {title || (
          <Tooltip title={description} style={{ marginLeft: 2, fontSize: 15 }}>
            <InfoOutlinedIcon />
          </Tooltip>
        )}
      </CommonText>
      <DevicePageLink to={`/devices/${serialNumber}`}>
        <InputIcon />
      </DevicePageLink>
    </IconLabelWrapper>
  );
};
