import { CommonText } from "components/common/text/text.component";
import React, { PropsWithChildren } from "react";
import style from "./content-row.module.css";

interface ContentRowProps extends PropsWithChildren {
  label: string;
  value?: string;
}

export function ContentRowComponent({ label, value, children }: ContentRowProps): JSX.Element {
  return (
    <div className={style.container}>
      <div className={style.labelContainer}>
        <CommonText>{label}</CommonText>
      </div>
      <div className={style.valueContainer}>
        {children}
        <CommonText>{value}</CommonText>
      </div>
    </div>
  );
}
